export * from './homeHero';
export * from './homeInstantValuation';
export * from './homeLatestProperties';
export * from './homeLocations';
export * from './homeWhoWeAre';
export * from './homeFooter';
export * from './homeGetAdvice';

export * from './searchResultsFilters';
export * from './searchResultsBanner';
export * from './searchResultsProperties';
export * from './searchResultsMap';
export * from './searchResultsSignup';

export * from './aboutDescription';
export * from './aboutTeam';
export * from './aboutOptions';
export * from './aboutOptionsWithTitle';
export * from './aboutContact';
export * from './aboutHero';
export * from './aboutOurValues';

export * from './singlePropertyHeaderSlider';
export * from './singlePropertyMainInfo';
export * from './singlePropertyContent';
export * from './singlePropertyAdvice';
export * from './singlePropertySimilarSlider';
export * from './singlePropertySimilarSliderbyID';

export * from './singlePropertyMap';
export * from './singlePropertyPlots';

export * from './buyHero';
export * from './buyLandingInfo';
export * from './buyPropertySlider';

export * from './sellHero';
export * from './sellLandingInfo';

export * from './rentHero';
export * from './rentStepByStep';

export * from './newHomesHero';
export * from './newHomesGetAdvice';
export * from './newhomesContact';

export * from './landlordsHero';
export * from './landlordLandingInfo';

export * from './stampHero';
export * from './helpHero';

export * from './helpToBuySlider';

export * from './developmentsHeader';
export * from './developmentsPricing';
export * from './developmentPropertyContent';


export * from './mortgagesHero';

