import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';

import {
  InputGroup,
  Button,
  Input,
  Checkbox,
  Textarea,

} from 'components/ui';
import sc from 'config/theme';
import { Sans } from 'Typography';
import { FormContext } from 'context/propertySearch';
import { searchOptions } from '../../../data';

import request from 'utils/request';



import bp from 'config/responsive';


const Container = styled.div`
  width: 100%;
  background: ${sc.colors.main};
  padding-top: 40px;
  padding-bottom: 60px;
  padding-left: 29px;
  padding-right: 28px;
  

  ${bp.computer} {
    padding-top: 22px;
    padding-bottom: 36px;
    padding-left: 29px;
    padding-right: 28px;
  }
  ${bp.large} {
    padding-top: 42px;
    padding-bottom: 76px;
    padding-left: 49px;
    padding-right: 48px;
  }
  
  & .newInput * {
    color: #0b1d3f;
  }

`;


const CallToActionContainer = styled.div`
  display: flex;
  align-items: center;

  ${Button} {
    width: calc(50% - ${sc.sizes.margin}px);
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  ${Checkbox} {
    width: calc(50% - ${sc.sizes.margin}px);
  }
`;



const Title = styled.div`
  margin-bottom: 25px;

  ${bp.computer} {

  }
  ${bp.large} {
    margin-bottom: 42px;
  }
`;






export class MortgagesForm extends PureComponent {
  static propTypes = {
    hideTabs: PT.bool,
    defaultChecked: PT.string,
  };

  static defaultProps = {
    defaultChecked: 'lettings',
  };

  state = {
    form: {
      first_name: "",
      last_name: "",
      contact_number: "",
      email: "",
      information: "",
      gdprChecked: true,


    },
    gdprWarning: false,

  };

  closeMenu = () => {
    this.setState({ menuIsOpen: false });
   };

  openMenuIsOpen = () => {
    this.setState({ menuIsOpen: true });

  };


  handleInputChange = (event) => {
    
      const name = event.target.name;
      const value = event.target.value;
      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value,
        },
      }));
  }

  handleGdprChange = () => {
    this.setState(({ form }) =>({
      form: {
        ...form,
        gdprChecked:  !form.gdprChecked,
      },
    }));
  }

  submitForm = async (event) => {

    console.log('FORM SUMMITED')
   

    var data = new FormData();


    for ( var key in this.state.form ) {
        data.append(key, this.state.form[key]);
    }


    data.append('subject', 'Mortgages services');

    event.preventDefault();

    console.log('AAAHERE');

    if (this.state.form.gdprChecked ) {
      console.log('HERE');
      try {

        this.setState({ formState: { ...this.state.formState, loading: true, sent: true }, gdprWarning: false });

        await request.post('forms' , data);

         console.log('there');
         console.log(data);

        this.setState({ formState: { ...this.state.formState, loading: false, success: true } })


      } catch (err) {
        this.setState({ formState: { ...this.state.formState, error: true, loading: false }});
      }
    } else {
      this.setState({ gdprWarning: true });
    }

  }


  render() {
    //const { hideTabs, defaultChecked , hideTabsfake} = this.props;
    //const { title, offerTypes } = this.props.content;
   // const { labels, selects } = this.props.content.form;

    return (
      <FormContext.Consumer>
        {({
          form,
        }) => {
          return (
            <Container>
              <Title>
                <Sans epsilon block color="white" weight="medium">
                Looking for advice on securing a mortgage? Fill out the form below.
                </Sans>
              </Title>



              <form onSubmit={this.submitForm}>


              <InputGroup>
                <Input  placeholder="First Name" name="first_name" onChange={this.handleInputChange}  required />
               <Input placeholder="Last Name" name="last_name" onChange={this.handleInputChange} required />

               </InputGroup>


              <InputGroup>

                <Input placeholder="Contact Number" name="contact_number" onChange={this.handleInputChange} required />
                <Input placeholder="Email" name="email" onChange={this.handleInputChange} 
                type="email"
		
                required />

              </InputGroup>

             
            <InputGroup>
                <Textarea name="information" placeholder="Further information" onChange={this.handleInputChange}  />
              </InputGroup>

              <CallToActionContainer>
                <Checkbox
                  label="This data will be sent to a third party and one of EB’s trusted partners."
                  name="includedata"
                  checked={form.gdprChecked}
                  onChange={this.handleGdprChange}
                  defaultChecked
                />
                 <Button alt block >
                   Submit
                 </Button>


              </CallToActionContainer>

              </form>
            </Container>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default MortgagesForm;
